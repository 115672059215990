<template>
  <div class="acn-job-list-item p-2" @click="$emit('click')">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div>
          <i
            v-if="_.isEmpty(agentProfile)"
            :class="[icon, 'mr-2 d-inline-block text-center']"
            :style="{ fontSize: '24px', width: '36px', color: '#454545' }"
          ></i>
          <avatar
            v-else
            :src="getAvatar"
            :name="_.get(agentProfile, 'name')"
            :size="36"
            class="mr-2"
          ></avatar>
        </div>

        <div>
          <span class="title d-block">{{ title }}</span>
          <span class="d-block content">
            {{ _.get(agentProfile, "name") || "Unassigned" }}
            <span v-if="isMe">(Me)</span>
          </span>
        </div>
      </div>
      <!-- Commission -->
      <span class="commission d-flex align-items-center">
        <div class="ml-2">
          <span class="d-block">
            <i class="fas fa-chart-pie mr-1" :style="{ color: '#aaa' }"></i>
            {{ commission }}
          </span>
          <span class="d-block font-regular" :style="{ color: '#666' }">
            {{ estimatedAmount }}
          </span>
        </div>
      </span>
      <!-- Slot -->
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Avatar: () => import("@/components/GlobalComponents/Avatar")
  },
  mixins: [],
  props: {
    title: {
      type: String
    },
    icon: {
      type: String,
      default: "fas fa-user"
    },
    user: {
      type: Object
    },
    commission: {
      type: [String, Number],
      default: 0
    },
    estimatedAmount: {
      type: [String, Number]
    }
  },
  data: function () {
    return {};
  },
  computed: {
    agentProfile() {
      return this.user?.agentProfile;
    },
    isMe() {
      return this.user?.id === this.$auth.user().id;
    },
    getAvatar() {
      return this.$getFileURL(this.agentProfile?.avatar?.[0]);
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.acn-job-list-item {
  &:hover {
    cursor: pointer;
    background: #f5f5f5;
    border-radius: 8px;
  }

  .title {
    font-size: 16px;
  }
  .content {
    color: #656565;
    font-weight: normal;
  }
  .commission {
    font-size: 16px;
    font-weight: normal;
    text-align: right;
  }
}
</style>
